import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'murder-hornet-ui';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { useLogoutMutation, useGetOrganizationBrandingQuery } from '../../redux/reducers/auth';
import SideNavSection from './SideNavSection';


const SideNav = ({ isOpen, toggle }) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const organization_uuid = useSelector((state) => state.auth.organization_uuid);

  const { data: organizationBranding = {} } = useGetOrganizationBrandingQuery(organization_uuid, { skip: !organization_uuid });
  const [logout] = useLogoutMutation();

  const location = useLocation();
  const navigate = useNavigate();

  const [, primaryPath] = location.pathname.split('/');

  return (
    <nav className={`sidenav ${isOpen ? 'active' : ''}`}>
      <div className='d-flex justify-content-between p-3'>
        <div className='sidenav-logo d-flex justify-content-start justify-content-sm-center'>
          {organizationBranding.logo_url && (
            <img
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  e.preventDefault();
                  navigate('/');
                }
              }}
              className='pointer'
              style={{ maxHeight: '100%', maxWidth: '100%' }}
              src={organizationBranding.logo_url}
              title='organization logo'
              onClick={() => {
                navigate('/');
                if (window.innerWidth <= 576) toggle();
              }}
            />
          )}
        </div>

        {/* Mobile SideNav close button */}
        <div className='d-sm-none'>
          <Button ghost onClick={toggle}>
            <span className='fas fa-times' />
          </Button>
        </div>
      </div>

      {organizationBranding.name && <p className='fs-5 fw-bold mb-0 px-3'>{organizationBranding.name}</p>}
      <p className='text-muted px-3'>Processor Portal</p>


      {isLoggedIn && (
        <>
          <Button
            ghost
            block
            className={`bg-light-hover pointer ps-3 pe-3 py-2 mb-3 rounded-0 text-reset fw-normal text-start ${primaryPath === 'dashboard' ? 'bg-primary-subtle fw-semibold' : ''}`}
            onClick={(e) => {
              navigate('/dashboard');
              if (e.currentTarget === document.activeElement) {
                e.currentTarget.blur();
              }
              if (window.innerWidth <= 576) toggle();
            }}
          >
            Dashboard
          </Button>

          <SideNavSection
            title='Pipelines'
            perform={[
              'processor:application:read-all',
              'processor:application:read-team',
              'processor:application:read-assigned',
              'processor:borrower:read-all',
              'processor:borrower:read-team',
              'processor:borrower:read-assigned',
              'processor:fs-pipeline:read-all',
            ]}
            toggle={toggle}
            items={[
              {
                id: 1,
                title: 'Applications',
                onClick: () => navigate('/applications'),
                isActive: primaryPath === 'applications',
                perform: [
                  'processor:application:read-all',
                  'processor:application:read-team',
                  'processor:application:read-assigned',
                ],
              },
              {
                id: 2,
                title: 'Borrowers',
                onClick: () => navigate('/borrowers'),
                isActive: primaryPath === 'borrowers',
                perform: [
                  'processor:borrower:read-all',
                  'processor:borrower:read-team',
                  'processor:borrower:read-assigned',
                ],
              },
              {
                id: 3,
                title: 'Funding Sources & Programs',
                onClick: () => navigate('/funding-sources-pipeline'),
                isActive: primaryPath === 'funding-sources-pipeline',
                perform: ['processor:fs-pipeline:read-all'],
              },
            ]}
          />

          <SideNavSection
            title='Reports'
            perform={[
              'processor:report:read-all-reports',
              'processor:report:read-kpi-charts',
            ]}
            toggle={toggle}
            items={[
              {
                id: 1,
                title: 'All Reports',
                onClick: () => navigate('/reports'),
                isActive: primaryPath === 'reports',
                perform: ['processor:report:read-all-reports'],
              },
              {
                id: 2,
                title: 'KPI Charts',
                onClick: () => navigate('/charts'),
                isActive: primaryPath === 'charts',
                perform: ['processor:report:read-kpi-charts'],
              },
            ]}
          />

          <SideNavSection
            title='Settings'
            toggle={toggle}
            items={[
              {
                id: 1,
                title: 'My Settings',
                onClick: () => navigate('/my-settings'),
                isActive: primaryPath === 'my-settings',
              },
              {
                id: 2,
                title: 'System Settings',
                onClick: () => navigate('/system-settings'),
                isActive: primaryPath === 'system-settings',
                perform: [
                  'processor:settings-organization:read',
                  'processor:settings-theme:read',
                  'processor:settings-lock-desk:read',
                  'processor:settings-rate-sheets:read',
                  'processor:settings-communication-templates:read',
                  'processor:settings-activity-log-note-templates:read',
                  'processor:settings-document-note-templates:read',
                  'processor:settings-milestones:read',
                  'processor:settings-statuses:read',
                  'processor:settings-flags:read',
                  'processor:settings-letters:read',
                  'processor:settings-checklist-templates:read',
                  'processor:settings-condition-templates:read',
                  'processor:settings-document-categories:read',
                  'processor:settings-form-data:read',
                ],
              },
              {
                id: 3,
                title: 'Funding Sources & Programs',
                onClick: () => navigate('/funding-sources'),
                isActive: primaryPath === 'funding-sources',
                perform: ['processor:funding-source-settings:read'],
              },
              {
                id: 4,
                title: 'Master Servicers',
                onClick: () => navigate('/servicers'),
                isActive: primaryPath === 'servicers',
                perform: ['processor:master-servicer-settings:read'],
              },
              {
                id: 5,
                title: 'Lenders',
                onClick: () => navigate('/lenders'),
                isActive: primaryPath === 'lenders',
                perform: ['processor:lending-partner-settings:read'],
              },
              {
                id: 6,
                title: 'Users',
                onClick: () => navigate('/users'),
                isActive: primaryPath === 'users',
                perform: ['processor:user-settings:read'],
              },
              {
                id: 7,
                title: 'Help',
                onClick: () => navigate('/help'),
                isActive: primaryPath === 'help',
              },
              {
                id: 8,
                title: 'Logout',
                onClick: logout,
              },
            ]}
          />
        </>
      )}
    </nav>
  );
};


SideNav.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};


export default SideNav;
