import axios from 'axios';
import ls from 'local-storage';
import { toast } from 'react-toastify';

import { store } from '../app';
import { startLogout } from '../redux/reducers/auth';
import { history } from '../routers/AppRouter';


// CREATE INSTANCE
const axiosInstance = axios.create();
axiosInstance.defaults.withCredentials = true;

// REQUEST INTERCEPTOR
axiosInstance.interceptors.request.use((config) => {
  let { url } = config;

  let isPrimaryDB = false;

  if (config.primaryDB) { // use "primaryDB: true" to automatically fetch from Primary DB every time
    isPrimaryDB = true;
  } else if (config.providesTags) {   // Read from Primary DB if fetch was triggered by RTK Query invalidation
    const dbLagSeconds = 5;

    for (let i = 0; i < config.providesTags.length; i++) {
      const invalidation = store.getState().invalidations[config.providesTags[i]];
      if (invalidation && (Date.now() < invalidation + (dbLagSeconds * 1000))) {
        isPrimaryDB = true;
        break;
      }
    }
  }

  if (isPrimaryDB) {
    const newURL = new URL(url);
    newURL.searchParams.append('db', 'primary');
    url = newURL.toString();
  }

  return { ...config, url };
});

// RESPONSE INTERCEPTOR
axiosInstance.interceptors.response.use((response) => response, (error) => {
  const { status } = error.response;
  if (status === 401) {
    const { isLoggedIn } = store.getState().auth;
    if (isLoggedIn) toast.warn('Something is wrong.  Please log back in.');
    store.dispatch(startLogout());

    const organization_uuid = ls('organizationBranding')?.organization_uuid;
    history.push(organization_uuid ? `/login/${organization_uuid}` : '/');
  }

  if (status === 503) {
    history.push('/unavailable');
  }

  if (status === 498) {
    const originalRequest = error.config;
    return new Promise((resolve, reject) => {
      // Hit the /refresh/access-token route for new accessToken
      axiosInstance.get(`${baseURL}/refresh/access-token`)
        .then(() => {
          axios(originalRequest)
            .then((respo) => resolve(respo))
            .catch((err) => {
              if (err.response.status === 401) {
                const { isLoggedIn } = store.getState().auth;
                if (isLoggedIn) toast.warn('Something is wrong.  Please log back in.');
                store.dispatch(startLogout());

                const organization_uuid = ls('organizationBranding')?.organization_uuid;
                history.push(organization_uuid ? `/login/${organization_uuid}` : '/');
              }
            });
        })
        .catch((err) => {
          console.error(err);
          return reject(err);
        });
    });
  }

  return Promise.reject(error);
});

export default axiosInstance;
